import classNames from "classnames"
import './WrapperWebField.scss'

interface WrapperWebFieldProps extends React.HTMLProps<HTMLDivElement> {
    isWeb: boolean;
    fullWidth?: boolean;
}

export function WrapperWebField({ isWeb, children, fullWidth, ...props }: WrapperWebFieldProps) {
  if (!isWeb) {
    return <>{children}</>
  }

  return (
    <div
      {...props}
      className={classNames(props.className, 'wrapper-web', {'wrapper-web_fullWidth' : fullWidth})}
    >
      {children}
    </div>
  )
}