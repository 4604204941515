import i18n from 'i18next'
import { Cookies } from 'react-cookie'
import { cookieOptions } from '../../lib/functions'

const cookies = new Cookies()

export function initLang(lang: string) {
  console.log("initLang", lang);
  cookies.set('lang', lang, cookieOptions)

  let pathname = window.location.pathname.split('/')
  pathname[1] = lang

  let urlHref = new URL(window.location.href)
  urlHref.pathname = pathname.join('/')
  window.history.replaceState(null, '', urlHref.href)
}
