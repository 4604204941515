import './NoSpaces.scss'
import { useLoginActions } from '../../state/slices/login'
import { useTranslation } from 'react-i18next'
import Heading from '../../shared/ui/Heading/Heading'
import { ListItem } from '@clout-team/web-components'
import { Add, ChevronRight, LenzaLogoError } from '@clout-team/icons/react'
import Divider from '../../shared/ui/Divider/Divider'
import { useAppSelector } from '../../shared/hooks/useAppSelector'
import { useAppDispatch } from '../../shared/hooks/useAppDispatch'
import { createNewAccount } from '../../shared/requests'

const NoSpacesWeb = () => {
  const { t, i18n } = useTranslation()
  const { setStep, setError, setWorkspaceUserHash } = useLoginActions()

  const email = useAppSelector((state) => state.login.mail ?? '')
  const hash = useAppSelector((state) => state.login.hash_code)
  const dispatch = useAppDispatch()

  const onChangeEmailHandler = () => {
    setError(null)
    setStep('START')
    setWorkspaceUserHash(null)
  }

  const onCreateAccountHandler = (hash_code: string | null) => {
    dispatch(createNewAccount({ hash_code }))
  }

  return (
    <div className='ns_container'>
      <LenzaLogoError className='ns_lenza_icon' />
      <Heading title={t('auth.new.no_spaces.title')} className='ns_title' />
      <div className='ns_description_wrapper'>
        <Heading description={t('auth.new.no_spaces.invitation_to_email')?.replace('{__mail__}', email)} align='left' className='ns_description ns_description_invite' />
        <Divider text={t('auth.new.or_upper').toUpperCase()} />
        <Heading description={t('auth.new.no_spaces.create_new_ws')} align='left' className='ns_description ns_description_create' />
      </div>
      <ListItem
        className={'ns_create_new_ws ns_create_new_ws_web'}
        icon={<Add />}
        title={t('auth.new.workspace.create_description')}
        description={t('auth.new.no_spaces.create_new_ws_link.description')}
        onClick={() => onCreateAccountHandler(hash)}
        iconRight={<ChevronRight />}
      />
      <p onClick={onChangeEmailHandler} className={'ns_back__link'} >
        {t('auth.new.no_spaces.link.back_to_home')}
      </p>
    </div>
  )
}

export default NoSpacesWeb
