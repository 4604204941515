import { getUrlParamByKey } from '@clout-team/helpers/dist/utils/web-utils'
import { Cookies } from 'react-cookie'
import { LANG_ARR } from '../../const/lang'
import { initLang } from './initLang'
import { getSystemLang } from './getSystemLang'

const cookies = new Cookies()

cookies.addChangeListener((change) => {
  console.log("CHANGE COOKIES", change);
})

export function getInitialLang() {
  let initialLang = getSystemLang();
  console.log('getSystemLang', initialLang);
  let urlLang = ''

  const url = document.location.pathname.split('/')

  if (url[1] && LANG_ARR.includes(url[1])) urlLang = url[1]
  console.log('urlLang', urlLang);

  let langFromCookie = null;
  if (cookies.get('check_lang')) {
    langFromCookie = cookies.get('lang');
  }
  else {
    cookies.set('check_lang', true);
  }

  console.log('langFromCookie', cookies.get('check_lang'), langFromCookie);
  const langFromParam = getUrlParamByKey('lang') // для приглашения
  console.log('langFromParam', langFromParam);
  if (langFromParam && LANG_ARR.indexOf(langFromParam) > -1) {
    console.log('initialLang 1', langFromParam);
    initialLang = langFromParam
  } else if (urlLang !== '' && LANG_ARR.indexOf(urlLang) > -1) {
    console.log('initialLang 2', langFromParam);
    initialLang = urlLang
  } else if (langFromCookie && LANG_ARR.indexOf(langFromCookie) > -1) {
    console.log('initialLang 3', langFromParam);
    initialLang = langFromCookie
  }
  console.log('initialLang end', initialLang);
  initLang(initialLang)

  return initialLang
}
