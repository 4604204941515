import './NoSpaces.scss'
import { useLoginActions } from '../../state/slices/login'
import { useTranslation } from 'react-i18next'
import MobileHeader from '../../shared/ui/MobileHeader/MobileHeader'
import Heading from '../../shared/ui/Heading/Heading'
import Divider from '../../shared/ui/Divider/Divider'
import { useAppSelector } from '../../shared/hooks/useAppSelector'
import { ListItem } from '@clout-team/web-components'
import { Add, ChevronRight, LenzaLogoError } from '@clout-team/icons/react'
import { useAppDispatch } from '../../shared/hooks/useAppDispatch'
import { createNewAccount } from '../../shared/requests'

const NoSpacesMobile = () => {
  const { t, i18n } = useTranslation()
  const { setStep, setError, setWorkspaceUserHash } = useLoginActions()
  const email = useAppSelector((state) => state.login.mail ?? '')
  const hash = useAppSelector((state) => state.login.hash_code)
  const dispatch = useAppDispatch()

  const onChangeEmailHandler = () => {
    setError(null)
    setStep('START')
    setWorkspaceUserHash(null)
  }

  const onCreateAccountHandler = (hash_code: string | null) => {
    dispatch(createNewAccount({ hash_code }))
  }

  return (
    <>
      <MobileHeader onClose={onChangeEmailHandler} />
      <div className='ns_container'>
        <LenzaLogoError className='ns_lenza_icon' /> 
        <Heading title={t('auth.new.no_spaces.title')} className='ns_title' />
        <div className='ns_description_wrapper'>
          <Heading description={t('auth.new.no_spaces.invitation_to_email')?.replace('{__mail__}', email)} align='center' className='ns_description ns_description_invite' />
          <Divider text={t('auth.new.or_upper').toLowerCase()} className='ns_description_divider' />
          <Heading description={t('auth.new.no_spaces.create_new_ws')} align='center' className='ns_description ns_description_create' />
        </div>     
        <ListItem
          className={'ns_create_new_ws'}
          icon={<Add />}
          title={t('auth.new.workspace.create_description.mobile')}
          description={t('auth.new.no_spaces.create_new_ws_link.description')}
          onClick={() => onCreateAccountHandler(hash)}
          iconRight={<ChevronRight />}
        />
        <p onClick={onChangeEmailHandler} className={'ns_back__link'} >
          {t('auth.new.no_spaces.link.back_to_home')}
        </p>
      </div>
    </>
  )
}

export default NoSpacesMobile
