import {appLangList, LANG_ARR, LANG_ARR_RFC} from '../../const/lang'

export function getSystemLang() {
  const osLang = navigator.language

  if (osLang.includes('ru')) {
    return 'ru'
  }

  if (LANG_ARR.includes(navigator.language)) return navigator.language;
  if (LANG_ARR_RFC.includes(navigator.language)) {
    try {
      const rfc = appLangList.find(lang => lang.rfc.includes(navigator.language));
      if (rfc) {
        return rfc.reduction;
      }
      const lang = appLangList.find(lang => lang.reduction === navigator.language);
      if (lang) {
        return lang.reduction;
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  return 'en'
}
