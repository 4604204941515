import './PageRedirect.scss'
import { isAndroid } from '@clout-team/helpers'
import { useCallback } from 'react'
import { config } from '@clout-team/web-components'
import { useTranslation } from 'react-i18next'

const { LENZA_NEWS_AVATAR, ANDROID_INSTALL_LINK, IOS_INSTALL_LINK } = config
const AVATAR_ANDROID =
  'https://lenzaos.com/wp-content/themes/lenzaos/uploads/images/Google_Play_Badge.png'
const AVATAR_IOS =
  'https://lenzaos.com/wp-content/themes/lenzaos/uploads/images/App_Store_Badge.png'

const PageRedirect = () => {
  const { t } = useTranslation()

  const handleOpenMarket = useCallback(() => {
    window.open(isAndroid() ? ANDROID_INSTALL_LINK : IOS_INSTALL_LINK, '_system')
  }, [])

  return (
    <div className={'component-page-redirect'}>
      <div className={'avatars-overlap'}>
        <div className={'avatars-icons'}>
          <div className={'ic ic-96 line'}>
            <div className={'ic ic-96 viewblock'} />
            <div className={'ic ic-96 viewblock'} />
            <div className={'ic ic-96 viewblock'} />
          </div>
          <div className={'ic ic-96 line'}>
            <div className={'ic ic-96 viewblock'} />
            <div className={'ic ic-96 viewblock'} />
          </div>
          <div className={'ic ic-120 viewblock'}>
            <img src={LENZA_NEWS_AVATAR} alt='' />
          </div>
        </div>
        <span className={'body-text-lenza'}>LENZA OS</span>
      </div>
      <span className={'body-text'}>
        {t('label.download_mobile_app', 'Загрузите мобильное приложения LENZA')}
      </span>
      <img
        className={'device-img'}
        onClick={handleOpenMarket}
        src={isAndroid() ? AVATAR_ANDROID : AVATAR_IOS}
        alt=''
      />
      <p className={'items'}>
        <span>{t('label.app_was_install', 'Уже установлено приложение?')}</span>
        <a
          className={'link-text'}
          href={
            isAndroid() ? ANDROID_INSTALL_LINK : IOS_INSTALL_LINK
            /*isAndroid() ? "com.mailchat.clout-dev://" : "com.mailchat.clout://"*/
          }
        >
          {t('label.open_in_app', 'Открыть LENZA')}
        </a>
      </p>
    </div>
  )
}

export default PageRedirect
