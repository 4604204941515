import { Button, CodeInput } from '@clout-team/web-components'
import React, { useEffect, useRef, useState } from 'react'
import {
  checkCodeByEmail,
  confirmCodeByEmail,
  IConfirmCode,
  requestCodeByEmail,
} from '../../shared/requests'
import { useAppSelector } from '../../shared/hooks/useAppSelector'
import { useLoginActions } from '../../state/slices/login'
import Heading from '../../shared/ui/Heading/Heading'
import './CodeStep.scss'
import { useAppDispatch } from '../../shared/hooks/useAppDispatch'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

const TIME = 180
let timeOut: ReturnType<typeof setTimeout>

const CodeStepWeb: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { setError, setStep, setWorkspaceUserHash, setIsLoading } = useLoginActions()

  const email = useAppSelector((state) => state.login.mail ?? '')
  const error = useAppSelector((state) => state.login.error)
  const isLoading = useAppSelector((state) => state.login.is_loading)
  const inviteHash = useAppSelector((state) => state.login.invite_hash)
  const confirmTime = useAppSelector((state) => state.login.confirmEmailByCode[email]);

  const [countEnterCode, setCountEnterCode] = useState(0)
  const disabledEnterCodeTimeout = useRef<NodeJS.Timeout | null>(null)

  const [countRepeatRequest, setCountRepeatRequest] = useState(0)

  const [timeState, setTimeState] = useState<number>(() => {
    if (confirmTime) {
      return TIME - ~~((Date.now() - confirmTime) / 1000);
    }
    return TIME;
  });
  const [isClear, setIsClear] = useState<boolean>(false)
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [code, setCode] = useState<any>('')

  const formatTimer = (seconds: number) => {
    if (seconds > 0) {
      const min = Math.floor(seconds / 60)
      const sec = seconds - min * 60
      let secString = `${sec}`

      if (sec < 10) {
        secString = `0${sec}`
      } else if (sec < 1) {
        secString = `00`
      }

      return `${min}:${secString}`
    } else {
      return ``
    }
  }

  const onChangeEmailHandler = () => {
    if (disabledEnterCodeTimeout.current) {
      clearTimeout(disabledEnterCodeTimeout.current)
    }
    setCountEnterCode(0)
    setError(null)
    setStep('START')
    setWorkspaceUserHash(null)
  }

  const onClickForRepeatHandler = () => {
    if (timeState === 0) {
      setIsClear(true)
      dispatch(requestCodeByEmail({ email: email }))
      setCountRepeatRequest(prev => ++prev)
      if (countRepeatRequest > 4) {
        setTimeState(600)
      } else {
        setTimeState(TIME)
      }

      // сброс ошибки количества попыток ввода кода
      if (disabledEnterCodeTimeout.current) {
        clearTimeout(disabledEnterCodeTimeout.current)
      }
      setCountEnterCode(0)
      if (error !== null) {
        setError(null)
      }
    }
  }

  const onCompleteHandler = (value: string) => {
    clearTimeout(timeOut)

    // TODO проверить компонент, дважды отрабатывает, для этого обернул таймаутом
    timeOut = setTimeout(async () => {
      setIsLoading(true)
      setError(null)

      const props: IConfirmCode = { email: email, code: value }
      if (inviteHash) props.hash_invite = inviteHash

      dispatch(checkCodeByEmail(props)).then((res) => {
        debugger
        if (res.meta.requestStatus === 'fulfilled' && res.payload?.response) {
          dispatch(confirmCodeByEmail(res.payload))
        } else {
          setIsClear(true);
          setIsDisabled(true)
          setCountEnterCode((prev) => ++prev)
          setError('auth.new.codestep.code_error')

          if (countEnterCode > 9) {
            setError('auth.new.codestep.code_error_number_attempts')
            disabledEnterCodeTimeout.current = setTimeout(() => {
              setCountEnterCode(0)
            }, 300000)
          }
        }
      })
    }, 100)
  }

  const onChangeHandler = (value: string) => {
    if (error && !isClear) {
      setError(null);
    }

    setCode(value)
    setIsClear(false)
    if (value.length === 6) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }

  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (timeState > 0) {
      timeout = setTimeout(() => {
        setTimeState((prev) => --prev)
      }, 1000)
    }
    return () => {
      if (timeout !== undefined) {
        clearTimeout(timeout)
      }
    }
  }, [timeState])

  return (
    <div className={'cd_code'}>
      <div className='cd_code_email_bg cd_code_email_bg_web'>
        <svg
          width='37'
          height='33'
          viewBox='0 0 37 33'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M0.899902 13.0283L18.2757 22.0966C18.667 22.3007 19.1334 22.3007 19.5246 22.0966L36.9002 13.0301V26.7497C36.9002 29.8692 34.4586 32.4184 31.3821 32.5904L31.0502 32.5997H6.75018C3.63072 32.5997 1.08154 30.1581 0.90944 27.0817L0.899902 26.7497V13.0283ZM2.88032 9.50441L18.2102 0.389334C18.5747 0.172617 19.0158 0.141658 19.4021 0.296455L19.5901 0.389334L34.92 9.50441C35.3432 9.75602 35.7099 10.0781 36.0088 10.4506L18.9002 19.377L1.79158 10.4506C2.01574 10.1712 2.27805 9.92019 2.57372 9.70601L2.88032 9.50441L18.2102 0.389334L2.88032 9.50441Z'
            fill='#201F1E'
            fill-opacity='0.16'
          />
        </svg>
      </div>
      <Heading
        className='cd_code_heading'
        title={t('code-step.check-email')}
        description={t('auth.new.codestep.description')?.replace('{__main__}', email)}
      />
      <div className={'cd_code__bottom'}>
        <CodeInput
          onValueChange={onChangeHandler}
          clear={isClear}
          type='digits'
          onComplete={onCompleteHandler}
          disabled={countEnterCode > 10}
          length={6}
          className={classNames({'cd_code__bottom_input__error': error !== null})}
        />

        {error !== null && <p className={'cd_code__error'}>{t(error)}</p>}

        <div className={'cd_code__bottom_buttons'}>
          <Button
            loading={isLoading}
            disabled={isDisabled}
            size={'lg'}
            fullWidth
            onClick={() => onCompleteHandler(code)}
          >
            {t('auth.new.main.continue_btn')}
          </Button>
          <Button
            size={'lg'}
            className={'cd_code__resend_btn'}
            color='white'
            disabled={timeState !== 0}
            fullWidth
            onClick={onClickForRepeatHandler}
          >
            {timeState !== 0
              ? `${t('auth.new.codestep.send_code_again_second')} (${formatTimer(timeState)})`
              : `${t('auth.new.codestep.send_code_again_second')}`}
          </Button>
          {countRepeatRequest > 5 && <p className={'cd_code__error'}>{t('auth.new.codestep.error_number_repeat_request')}</p>}
          <p onClick={onChangeEmailHandler} className={'cd_code__link'}>
            {t('auth.new.profile.back_btn')}
          </p>
        </div>
      </div>
    </div>
  )
}

export default CodeStepWeb
