import Copy from '@clout-team/icons/react/Copy'
import Link from '@clout-team/icons/react/Link'
import classNames from 'classnames'
import React, { ReactNode, useState } from 'react'
import './CopyLink.scss'
import { useTranslation } from 'react-i18next'

interface CopyLinkProps {
  copyTarget: string
  trigger?: ReactNode
  onCopy?: () => void
}

const CopyLink: React.FC<CopyLinkProps> = ({ copyTarget, trigger, onCopy }) => {
  const { t } = useTranslation()

  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [unmounting, setUnmounting] = useState<boolean>(false)

  const onCopyHandler = () => {
    navigator.clipboard.writeText(copyTarget)
    onCopy?.()
    setIsVisible(true)
    setTimeout(() => {
      setUnmounting(true)
    }, 2500)
    setTimeout(() => {
      setIsVisible(false)
      setUnmounting(false)
    }, 3000)
  }

  return (
    <>
      <div className={'cp_wrapper'} onClick={onCopyHandler}>
        {trigger ?? (
          <p className={'cp_copy'}>
            <Link className={'cp_copy__icon'} />
            {t('auth.new.invite.copy_link')}
          </p>
        )}
      </div>

      {isVisible && (
        <div className={classNames('cp_popup', { cp_popup_unmount: unmounting })}>
          <Copy className={'cp_popup__icon'} />
          {t('auth.new.invite.popup_copied_message')}
        </div>
      )}
    </>
  )
}

export default CopyLink
