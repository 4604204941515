export interface ILangList {
    title: string
    image: any
    rfc: string[],
    reduction: string
    currentKeyName: string
}

export const appLangList: Array<ILangList> = [
    {
        currentKeyName: 'auth.label.united_states',
        reduction: 'en',
        rfc: [
            'en',
            'en-AU',
            'en-BZ',
            'en-CA',
            'en-CB',
            'en-IE',
            'en-JM',
            'en-NZ',
            'en-PH',
            'en-TT',
            'en-US',
            'en-ZA',
            'en-ZW'
        ],
        image: null,
        title: 'English (currentName)'
    },
    {
        currentKeyName: 'auth.label.united_kingdom',
        reduction: 'en-GB',
        rfc: ['en-GB'],
        image: null,
        title: 'English (currentName)'
    },
    {
        currentKeyName: 'auth.label.germany',
        reduction: 'de',
        rfc: [
            'de-AT',
            'de-CH',
            'de-DE',
            'de-LI',
            'de-LU',
        ],
        image: null,
        title: 'Deutsch (currentName)'
    },
    {
        currentKeyName: 'auth.label.spain',
        reduction: 'es',
        rfc: [
            'es-AR',
            'es-BO',
            'es-CL',
            'es-CO',
            'es-CR',
            'es-DO',
            'es-EC',
            'es-ES',
            'es-GT',
            'es-HN',
            'es-MX',
            'es-NI',
            'es-PA',
            'es-PE',
            'es-PR',
            'es-PY',
            'es-SV',
            'es-UY',
            'es-VE',
        ],
        image: null,
        title: 'Español (currentName)'
    },
    {
        currentKeyName: 'auth.label.france', reduction: 'fr', rfc: [
            'fr-BE',
            'fr-CA',
            'fr-CH',
            'fr-FR',
            'fr-LU',
            'fr-MC',
        ], image: null, title: 'Français (currentName)'
    },
    {
        currentKeyName: 'auth.label.italy', reduction: 'it', rfc: [
            'it-CH',
            'it-IT'
        ], image: null, title: 'Italiano (currentName)'
    },
    {
        currentKeyName: 'auth.label.brasil', reduction: 'pt', rfc: [
            'pt-BR',
            'pt-PT',
        ], image: null, title: 'Português (currentName)'
    },
    {
        currentKeyName: 'auth.label.russia', reduction: 'ru', rfc: [
            'ru-RU'
        ], image: null, title: 'Русский (currentName)'
    },
    {
        currentKeyName: 'auth.label.united_arab_emirates', reduction: 'ar', rfc: [
            'ar-AE',
            'ar-BH',
            'ar-DZ',
            'ar-EG',
            'ar-IQ',
            'ar-JO',
            'ar-KW',
            'ar-LB',
            'ar-LY',
            'ar-MA',
            'ar-OM',
            'ar-QA',
            'ar-SA',
            'ar-SY',
            'ar-TN',
            'ar-YE',
        ], image: null, title: 'العربية (currentName)'
    },
    {
        currentKeyName: 'auth.label.japan', reduction: 'ja', rfc: [
            'ja-JP'
        ], image: null, title: '日本語 (currentName)'
    },
    {
        currentKeyName: 'auth.label.china', reduction: 'cn', rfc: [
            'zh-CN',
            'zh-HK',
            'zh-MO',
            'zh-SG',
            'zh-TW'
        ], image: null, title: '繁體中文 (currentName)'
    },
    {currentKeyName: 'auth.label.korea', reduction: 'ko', rfc: ['ko-KR'], image: null, title: '한국어 (currentName)'},
    {currentKeyName: 'auth.label.india', reduction: 'hi', rfc: ['hi-IN'], image: null, title: 'हिन्दी (currentName)'}
]

export const LANG_ARR = appLangList.map((lang) => lang.reduction);
export const LANG_ARR_RFC = appLangList.map((lang) => lang.rfc).flat();