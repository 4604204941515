import { LenzaLogoError } from "@clout-team/icons/react";
import "./InviteInvalid.scss"
import {useTranslation} from "react-i18next";

const InviteInvalid = () => {
    const {t} = useTranslation();

    const onClickHandler = () => {
        document.location.href = document.location.origin
    }

    return (
        <div className={'inv_invalid'}>
            <LenzaLogoError className='inv_invalid_lenza_icon' />
            <h2 className={'inv_invalid__heading'}>{t('auth.new.no_longer_valid.title_second')}</h2>
            <p className={'inv_invalid__description'}>{t('auth.new.no_longer_valid.description_link_invalid')}</p>
            <p onClick={onClickHandler} className={'inv_invalid_back__link'} >
                {t('auth.new.no_spaces.link.back_to_home')}
            </p>
        </div>
    )
}

export default InviteInvalid
