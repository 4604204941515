import './Promo.scss'
import { Slider } from '@clout-team/web-components'
import React, { useEffect, useMemo, useState } from 'react'
import { useLoginActions } from '../../state/slices/login'
import ButtonUI from '../../shared/ui/ButtonUI/ButtonUI'
import Promo1Image from '../../assets/images/promo1.png'
import Promo2Image from '../../assets/images/promo2.png'
import Promo3Image from '../../assets/images/promo3.png'
import { useTranslation } from 'react-i18next'

const PromoWeb = () => {
  const { t, i18n } = useTranslation()
  const { setStep } = useLoginActions()

  const [activeSlide, setActiveSlide] = useState(0)
  const [showSlider, setShowSlider] = useState(false)

  const sliderItems = useMemo(() => {
    const getWrapper = (title: string, description: string, img: any) => (
      <div className={'pr_slider_item pr_slider_item_web'}>
        <img className='pr_slider_image pr_slider_image_web' src={img} />
        <div className='pr_slider_info pr_slider_info_web'>
          <p className='pr_slider_title pr_slider_title_web'>{title}</p>
          <p className='pr_slider_description'>{description}</p>
        </div>
      </div>
    )

    const sliderItemsArr = [
        getWrapper(t('promo.slider.1.title'), t('promo.slider.1.description'), Promo1Image),
        getWrapper(t('promo.slider.2.title'), t('promo.slider.2.description'), Promo2Image),
        getWrapper(t('promo.slider.3.title'), t('promo.slider.3.description'), Promo3Image),
      ]

    return [sliderItemsArr[activeSlide]]
  }, [t, activeSlide])

  useEffect(() => {
    const interval = setInterval(() => setActiveSlide(prev => prev === 2 ? 0 : ++prev), 6000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    setTimeout(() => setShowSlider(true))
    setTimeout(() => setShowSlider(false), 5200)
  }, [activeSlide])

  const onOpenLoginPage = () => {
    setStep('START')
  }

  return (
    <div className='pr_container pr_container__web'>
      <Slider
        className={'pr-slider pr-slider_web' + (showSlider ? ' pr-slider_web_show' : '')}
        headerClassName={'pr-slider_header'}
        items={sliderItems}
        titles={['']}
        // onChangeItem={(index) => setActiveSlide(index)}
      />
      <div className='pr_footer_web'>
        <ButtonUI size={'lg'} fullWidth onClick={onOpenLoginPage}>
          {t('promo.start')}
        </ButtonUI>
      </div>
    </div>
  )
}

export default PromoWeb
