import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'

import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import {getInitialLang} from './getInitInitialLang'

declare module 'i18next' {
    interface CustomTypeOptions {
        returnNull: false
    }
}
const defaultLang = getInitialLang();

console.log("defaultLang", defaultLang);

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: defaultLang,
        debug: true,

        defaultNS: 'translation',
        interpolation: {
            escapeValue: false,
        },
        detection: {
            caches: [''],
        },
        backend: {
            loadPath: '/locales/{{lng}}.json',
        },
    })

export default i18n
