import classNames from 'classnames';
import './Divider.scss'

interface DividerProps {
    text: string;
    className?: string;
}

const Divider = ({ text, className }: DividerProps) => {
    return (
        <div className={classNames('d_divider', className)}>
          <hr />
            <p>{text}</p>
          <hr />
        </div>
    )
}

export default Divider