import React, { useState } from 'react'
import './LanguageSwitch.scss'
import {
  Button,
  config,
  ContextualMenu,
  LangSystem,
  Layout,
  Text,
} from '@clout-team/web-components'
import ChevronDownFilled from '@clout-team/icons/react/ChevronDownFilled'
import { getCountryImage } from '../../../assets/country'
import { useTranslation } from 'react-i18next'
import { appLangList } from '../../const/lang'
import { changeLang } from '../../config/i18n/changeLang'

const LanguageSwitch = () => {
  const { t, i18n } = useTranslation()

  const [selectedLang, setSelectedLang] = useState(() =>
    appLangList.find((v) => {
      return v.reduction === i18n.language
    }),
  )

  const [countryContext, setCountryContext] = useState(null)

  const onLanguageSwitch = (lang: string) => {
    changeLang(lang)
    document.documentElement?.setAttribute('lang', lang)
    LangSystem.changeLang(lang)
  }

  return (
    <>
      {countryContext && (
        <ContextualMenu
          isModal
          withWrapper={true}
          alignToElement={countryContext}
          menuList={appLangList.map((v) => {
            return {
              onClick: () => {
                setSelectedLang(v)
                setCountryContext(null)
                onLanguageSwitch(v.reduction)
              },
              title: v.title.replace('currentName', t(v.currentKeyName)),
              icon: getCountryImage(v.reduction),
            }
          })}
          onClose={() => {
            setCountryContext(null)
          }}
          closeClickedInside={false}
        />
      )}
      <Button
        className={'lang-switch'}
        iconRight={config.IS_MOBILE ? null : <ChevronDownFilled />}
        color={'white'}
        inverted={!config.IS_MOBILE}
        onClick={(e: any) => {
          setCountryContext(e.currentTarget)
        }}
      >
        <Layout gap={'xsm'} isAlignCenter>
          <div className={'icon'}>
            {selectedLang ? getCountryImage(selectedLang.reduction) : ''}
          </div>
          {selectedLang && (
            <Text>
              {selectedLang.title.replace('(currentName)', '')}
              {/* {config.IS_MOBILE
                ? selectedLang.title.replace('(currentName)', '')
                : selectedLang.title.replace(
                    'currentName',
                    t(selectedLang.currentKeyName),
                  )} */}
            </Text>
          )}
        </Layout>
      </Button>
    </>
  )
}

export default LanguageSwitch
