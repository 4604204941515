import { useCallback, useState } from 'react'
import { useLoginActions } from '../../../state/slices/login'
import './Check.scss'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../../shared/hooks/useAppSelector'
import ButtonUI from '../../../shared/ui/ButtonUI/ButtonUI'

interface ICheck {
  corpDomains: string[]
  isMyDomain?: boolean
  goToFinishReg: () => void;
}

const Check = (props: ICheck) => {
  const { corpDomains, isMyDomain, goToFinishReg } = props

  const { t } = useTranslation()

  const { setIsMyDomainCorp, setIsDomainInvite } = useLoginActions()

  const [isShow, setIsShow] = useState<boolean>(false)
  const companyName = useAppSelector((state) => state.login.company_name)

  const onApproveHandler = () => {
    if (isMyDomain) {
      setIsMyDomainCorp(true)
    } else setIsDomainInvite(true)
    goToFinishReg()
  }

  const onCopyHandler = useCallback(() => {
    navigator.clipboard.writeText(`https://${companyName}.lenzaos.com`)
    setIsShow(true)
    setTimeout(() => {
      setIsShow(false)
    }, 700)
  }, [isShow, companyName])

  const domainsText = t('approved-ds.description').split('{{ value }}')

  return corpDomains.length > 0 ? (
    <>
      <div className={'ch_check'}>
        <div className={'ch_check__top'}>
          <p className={'ch_check__emoji'}>📫</p>
          <p className={'ch_check__top_description'}>{t('approved-ds.label')}</p>
        </div>
        <div className={'ch_check__bot'}>
          <div className={'ch_check__bot_wrapper'}>
            <p className={'ch_check__bot_description'}>
              {domainsText[0]}
              {corpDomains.map((el, index) => (
                <b key={index}>{'@' + el + ' '}</b>
              ))}
              {domainsText[1]}
            </p>
          </div>
        </div>
      </div>
      <ButtonUI size={'lg'} fullWidth className='ch_check_btn_approve' onClick={onApproveHandler}>
        {t('approved-ds.approve')}
      </ButtonUI>
      <p onClick={goToFinishReg} className={'ch_check_skip'} >
        {t('auth.new.friends.skip')}
      </p>
    </>
  ) : null
}

export default Check
